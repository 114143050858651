<template>
  <div class="nav-bar">
    <nav class="navbar position-static navbar-expand-lg navbar-light bg-white nav-1">
      <div class="container mw-0 px-3">

        <a class="navbar-brand" href="#">
        <img src="../assets/favicon.png" width="" height="27" class="d-inline-block align-top" alt="" loading="lazy">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link class="nav-link" :to = "{ name:'posts' }" exact>Devices</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to= "{ name:'dueClear' }">Clear Dues</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to= " {name:'userList'}"> User List </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to= " {name:'posts'}"> Refill Product </router-link>
            </li>
            <!-- <li class="nav-item">
              <router-link class="nav-link" :to= "{ name:'complain' }">Complain</router-link>
            </li> -->
            <li class="nav-item dropdown">
              
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Complain
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <router-link class="dropdown-item" :to= " { name: 'complain' }"> Log Complain </router-link>
                  <router-link class="dropdown-item" :to= " { name: 'ComplainStatus' }"> Complain Status </router-link>  
                  
                
              </div>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to= " {name:'impactData'}"> Impact Data </router-link>
            </li>
            
          </ul>
          <ul class="nav navbar-nav navbar-right">
                <li>
                    <div class="btn-nav"><a @click="logout" class="btn btn-light btn-small navbar-btn" >Logout</a>
                    </div>
                </li>
            </ul>
        </div>
        <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
            <router-link :to = "{ name:'posts' }" exact>Posts</router-link>
            </li>
            <li class="nav-item">
              <router-link :to = "{}" exact>Due Clearing </router-link>
            </li>
          </ul>
        </div>

      </div> -->
    </nav>
  </div>
</template>

<script>
  export default {
    name: 'Navbar',
    methods: {
      logout(){
        this.$store.dispatch('logoutUser')
        .then(() => {
          this.$router.push({name: 'Login'})
        })
        .catch(err => {
          console.log(err)
          
        })
      }
    }
  }
</script>

<style scoped>
  .navbar {
    overflow: visible;
    background-color: #333;
    position: static;
    width: 100%;
    top:0;
    left:0;
    /* padding-top: 0; */
    /* width:100%;
    margin-top: 0px;
     */
    /* top: 0; 
    margin-bottom: 20px;
     Full width */
  }
  a {
    color:#000;
  }
  
    /* .nav-item {
      padding-left: 4;
    } */
</style>
