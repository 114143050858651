
<template>
    <div class="Final Requisition">
        <Navbar></Navbar>
        <div class = "container pt-3 pb-5">
            <div class="row align-items-center">
                <div class="col-md text-center">
                                         <h4 style="color: red; text-align: center;"><b>  ডিভাইস টেস্ট করলে Test Mode এ ক্লিক করবেন। টেস্ট করা হয়ে গেলে Live Mode এ ক্লিক করবেন।</b> </h4>
                                         
                                                         <h3 style="color: black;">Test Mode: {{DeviceSelected.test_mode}}</h3>
    
                                                                 
                    <button @click="OnClickLiveMode()" class="btn btn-success pull-right btn-lg">Live Mode</button>
                    <button @click="OnClickTestMode()" class="btn btn-warning pull-right btn-lg">Test Mode</button>
                    
                    <br>
                    <br>
                <h4 style="color: red;"><b> প্রোডাক্ট রিফিলের আগে ইনভেন্টরি ম্যানেজার থেকে অবশ্যই প্রোডাক্ট নিতে হবে।</b> </h4>
                    <h2> {{DeviceSelected.name}} </h2>
    
                    <h2> {{DeviceSelected.area_name}} </h2>
                    <h2> Total Products: {{DeviceSelected.total_products}} </h2>
    
    
    
                </div>
            </div>
            
            <div class="table-responsive mt-3" v-if="magazines.length">
                 <h3 style="color: red; text-align: center;"><b> আপনার কাছে যে সকল প্রডাক্ট আছে তার একটা লিস্ট নিচে দেওয়া হলো।</b> </h3>
                <table
                    class="table table-bordered table-hover text-center cart-table"
                    v-if="magazines.length">
                    <thead class="thead-dark">
                        <tr class="table-head">
                            <th class="col">Product</th>
                            <th class="d-none d-md-table-cell d-lg-table-cell">Product SKU</th>
                            <th class="col">Full Pack</th>
                            <th class="col">Single Pad</th>
                            <th class="d-none d-md-table-cell d-lg-table-cell">Receiving Date</th>
                         
                        </tr>
                    </thead>   
                    <tbody  v-for="(item1, index) in agent_products" :key="index">
                        <tr>
                            <td >
                                <h5> {{ item1.product_type }}</h5>
                            </td>
                            <td class="d-none d-md-table-cell d-lg-table-cell" >
                                <h5> {{ item1.sku }}</h5>
                            </td>
                            <td >
                                <h5> {{ item1.count }}</h5>
                            </td>
                            <td >
                                <h5> {{ item1.single_count }}</h5>
                            </td>
                            <td class = "d-none d-md-table-cell d-lg-table-cell">
                                <h5> {{ parseDateString(item1.sending_date) }}</h5>
                            </td>
    
                        </tr>
                        </tbody>
                    
                </table>
            </div>
            <div class="table-responsive mt-3" v-if="magazines.length">
                <table
                    class="table table-bordered table-hover text-center cart-table"
                    v-if="magazines.length">
                    <thead class="thead-dark">
                        <tr class="table-head">
                            <th class="d-none d-md-table-cell d-lg-table-cell">Magazine Number</th>
                            <th scope="col">Product</th>
                            <th scope="col">Maximum Capacity</th>
                            <th scope="col">Available</th>
                            <th scope="col">Refill Quantity</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>   
                    <tbody  v-for="(item1, index) in magazines" :key="index">
                        <tr>
                            <td class="d-none d-md-table-cell d-lg-table-cell" >
                                <h6> {{ item1.magazine_number }}</h6>
                            </td>
                            <td >
                                <h6> {{ item1.product_type }}</h6>
                            </td>
                            <td >
                                <h6> {{ item1.max_capacity }}</h6>
                            </td>
                            <td >
                                <h6> {{ item1.count }}</h6>
                            </td>
                            <td >
                            <!-- <input type="text" v-model="selectedRequestProduct" placeholder=""> -->
                                <b-form>
                                    <b-form-group id="text-group"  label-for="text-input">
                                        <b-form-input v-model="selectedRequestProduct[index]"  type="text" placeholder="">{{selectedRequestProduct[index]}}</b-form-input>
                                    </b-form-group>
    
                                </b-form>
                            </td>
    
                            <td>
                                <button @click="OnClickRefillProduct(item1, index)" :disabled="disableRefillButton"
                                :class="{'disabled': disableRefillButton}"
                                class="btn btn-danger pull-right btn-sm">Refill</button>
                                
                            </td>
                        </tr>
                        </tbody>
                    
                </table>
            </div>
    
            <!-- <div class="row">
                <div class="col-md center-block text-center">
                    <h4> Name </h4>
                </div>
                <div class="col-md center-block text-center">
                    <h4> {{DeviceSelected.name}} </h4>
                </div>
            </div> -->
        </div>
    </div>
    </template>
    
    <script>
    
    import Navbar from './components/Navbar.vue';
    import { getAPI } from './axios-api.js';
    import { BFormInput,BFormGroup,BForm } from 'bootstrap-vue'
    //import { mapState } from 'vuex'
    import moment from 'moment'
    
    export default {
        name:'Requsition',
        components: {
            Navbar,
            'b-form-input':BFormInput,
            'b-form-group':BFormGroup,
            'b-form':BForm
        },
        
        created(){
            this.loadData()
            console.log("here")
            // console.log(this.DeviceSelected.name)
            // console.log(this.DeviceSelected)
        },
        data(){
            return{
                DeviceSelected: '',
                DeviceSelectedName: '',
                magazines: '',
                selectedRequestProduct: [],
                agent_products: '',
                tmode: 'False',
                status: '',
                
            }
        },
        methods: {
            parseDateString(date) {
                return moment(String(date)).format('DD.MM.YYYY')
            },
            async loadData(){
                var disdata = {}
                // console.log(localStorage.getItem('selectedDevice'))
                 
                this.DeviceSelectedName = localStorage.getItem('DeviceSelected')
                console.log(this.DeviceSelectedName)
                const payload = {
                    name: this.DeviceSelectedName
                }
                getAPI.post('/singleProduct/', payload, { headers: { 
                        Authorization: `Bearer ${localStorage.getItem('access_token')}` 
                }})
                .then(response => {
                    disdata = response['data']['SRU'];
                    console.log(disdata);
                    this.DeviceSelected = disdata;
                })
    
                getAPI.post('/getMagazines/', payload, { headers: { 
                        Authorization: `Bearer ${localStorage.getItem('access_token')}` 
                }})
                .then(response => {
                    disdata = response['data'];
                    console.log(disdata);
                    this.magazines = disdata['magazines']
                })
    
                getAPI.post('/agentProducts/', payload, { headers: { 
                        Authorization: `Bearer ${localStorage.getItem('access_token')}` 
                }})
                .then(response => {
                    disdata = response['data'];
                    console.log(disdata);
                    this.agent_products = disdata['agent_product']
                })
    
                console.log(this.$store.state.selectedDevice)
            },
            async OnClickRefillProduct(item,index) {
                console.log(this.selectedRequestProductindex);
                this.disableRefillButton=true;
                
                const payload = {
                    magazineInfo: item,
                    quantity: '' + this.selectedRequestProduct[index],
                    Device: this.DeviceSelectedName,
    
                };
                console.log("Recharge payload", payload);
                if (typeof payload.amount !== 'undefined'){
                    console.log("Payment amount OK!")
                }
                else if(payload.quantity == 0){
                    console.log("Quantity Problem")
                    alert("Quantity must be greater than 0");
                }
                else if (((parseInt(payload.quantity) + parseInt(item.count)) > parseInt(item.max_capacity)))        
                {
                    alert("Quantity is greater than max capacity");
                }
                else
                {
                    
                        var disdata = {}
    
                        try {
            const response = await getAPI.post('/refillProduct/', payload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`
                }
            });
            disdata = response.data;
            console.log(disdata);
            this.$router.go();
            alert("Refill Complete");
        } catch (error) {
            console.error("Refill request failed:", error);
            alert("Refill request failed. Please try again later.");
        } finally {
            // Enable the refill button after request completion (success or failure)
            this.disableRefillButton = false;
        }
    
                }
            },
            async OnClickTestMode() {
                console.log(this.selectedRequestProductindex)
                
                const payload = {
                    Device: this.DeviceSelectedName,
                    Mode: 1,
    
                };
                                
                var disdata = {}
    
                getAPI.post('/modeChange/', payload, { headers: { 
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`
                }})
                .then(response => {
                    disdata = response['data'];
                    console.log(disdata);
                    this.tmode =  'True';
                    //this.$router.go()
                    alert("Device Now is in Test Mode\n ডিভাইস টি এখন টেস্ট মুডে আছে।");
                    this.$router.go()
                })
    
                
            },
            async OnClickLiveMode() {
                console.log(this.selectedRequestProductindex)
                
                const payload = {
                    Device: this.DeviceSelectedName,
                    Mode: 0,
    
                };
                                
                var disdata = {}
    
                getAPI.post('/modeChange/', payload, { headers: { 
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`
                }})
                .then(response => {
                    disdata = response['data'];
                    console.log(disdata);
                    this.tmode =  'False';
                    //this.$router.go()
                    alert("Device Now is in Live Mode\n ডিভাইস টি এখন লাইভ মুডে আছে।");
                    this.$router.go()
    
                  })
    
                
            },
        }
    }
    </script>
    
    <style scoped>
    
    .disabled {
        background: rgba(0,0,0,0.2);
        color: white;
        border-color: transparent;
      }
      button.disabled:hover {
        cursor: default;
        animation: shake 0.6s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }
      
      @keyframes shake {
        10%, 90% {
          transform: translate3d(-1px, 0, 0);
        }
        
        20%, 80% {
          transform: translate3d(1px, 0, 0);
        }
      
        30%, 50%, 70% {
          transform: translate3d(-2px, 0, 0);
        }
      
        40%, 60% {
          transform: translate3d(2px, 0, 0);
        }
      }
    </style>
    
