<template>
  <div class="Requisition">
    <Navbar></Navbar> 
      <div class="album py-5 bg-light">
        <h2 style="text-align:center;">ড্রপ ডাউন অপশন থেকে ডিভাইস সিলেক্ট করুন। </h2>
          <div class="container">
            <div class="row">
             <br>
              <div class="cascading-dropdown">
    <div class="dropdown">
      <H2>Select Device Name</H2>
      <select v-model="deviceSelected" @change="onChangeDevice">
       
        <option
          v-for="ruData in APIData" :key="ruData.id" 
          :value="ruData.name"
         
        >
          {{ ruData.name }}
        </option>
      </select>
    </div>
    </div>
    <br>
              <div v-for="ruData in RUData" :key="ruData.id" class="col-md-4">
                <div class="card mb-4 box-shadow">
                 
                  <div class="card-body">
                      <h4 class=""><a class="text-secondary" href="">{{ruData.name}}</a></h4>
                      <p class="card-text">{{ruData.area_name}}</p>
  
                        <h3 style="color: black;">Device Active: <span v-if="!ruData.active" class="" style="color: red;">No </span>
                         <span v-else class="" style="color: green;">Yes </span></h3>
                     
                   
                      
                      <div class="d-flex justify-content-between align-items-center">
                      <div class="btn-group">
                      <a @click="selectedDevice(ruData)" class="btn btn-sm btn-outline-primary" role="button" aria-pressed="true">Refill Product</a>
                      </div>

                      <div class="btn-group">
                      <a @click="tryselectedDevice(ruData)" class="btn btn-sm btn-outline-primary" role="button" aria-pressed="true">Mismatch</a>
                      <!-- <a href="" class="btn btn-sm btn-outline-secondary" role="button" aria-pressed="true">Edit</a> -->
                      </div>




                      <!-- <small class="text-muted">9 mins</small> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
  import { getAPI } from './axios-api.js'
  import Navbar from './components/Navbar.vue'
  import { mapState } from 'vuex'
  export default {
    name: 'Posts',
   
    components: {
      Navbar
    },
  data() {
    return {
        search: '',
        deviceSelected: '',
        trydeviceSelected: '',
        RUData: ''
    }
  },
  computed: mapState(['APIData']),
    created () {
        console.log("here")
        // console.log(this.$store.state.accessToken)
        // getAPI.get('/posts/', { headers: { 
        //   Authorization: `Bearer ${localStorage.getItem('access_token')}` 
        //   } })
        //   .then(response => {
        //     // console.log(response.data)
        //     this.$store.state.APIData = response.data
            
        //   })
        //   .catch(err => {
        //     console.log(err)
        //   })
        //alert("ডিভাইস টেস্ট করলে Test Mode এ ক্লিক করবেন। টেস্ট করা হয়ে গেলে Live Mode এ ক্লিক করবেন।");
        getAPI.get('/requestName/',{ headers: { 
          Authorization: `Bearer ${localStorage.getItem('access_token')}` 
          } })
          .then(response => {
            this.$store.state.APIData = response.data["ruData"]
            console.log(response.data)
            // console.log("Getting Data")

            
          })
          .catch(err => {
            console.log(err)
          })
    }
    
    ,methods : {
    
      selectedDevice(selectedDevice){
        console.log("Device Selected")
        console.log(selectedDevice.name)
        this.$store.dispatch('DeviceInfo',selectedDevice)
        // this.$store.state.selectedDevice = this.DeviceSelected
        this.$router.push({name:'Requisition'})
      },

      tryselectedDevice(tryselectedDevice){
        console.log("Device Selected")
        console.log(tryselectedDevice.name)
        this.$store.dispatch('DeviceInfo',tryselectedDevice)
        this.$router.push({name:'tryRequisition'})
      },



      async onChangeDevice() {
            var disdata = {}
            const payload = {
                name: this.deviceSelected
            }
            
            getAPI.post('/requestProduct/', payload, { headers: { 
                    Authorization: `Bearer ${localStorage.getItem('access_token')}` 
            }})
            .then(response => {
                disdata = response['data'];
                console.log(disdata);
                this.RUData = disdata['ruData']
            })
      },
    
    }
    
  }
</script>

<style scoped>
    .container {
        padding-top: 30px !important;
    }
</style>
