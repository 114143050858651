import Vue from 'vue'
import { Notifications } from 'vue-notification'
import VueSimpleAlert from "vue-simple-alert";
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.config.productionTip = false

new Vue({
  router,
  store,
  BootstrapVue,
  Notifications,
  render: h => h(App),
}).$mount('#app')

Vue.use(Notifications)
 
Vue.use(VueSimpleAlert);
