import Vue from 'vue'
import Vuex from 'vuex'
import { getAPI } from './axios-api'
import jwt_decode from "jwt-decode"
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)
export default new Vuex.Store({

    plugins: [createPersistedState()],
    state: {
        accessToken: null,
        refreshToken: null,
        isLoggedIn: false,
        APIData: '',
        DeviceSelected : '',
        usableData: '',
    },
    getters : {
        Dev : state => {
            return state.DeviceSelected
        }
    },
    mutations: {
        updateStorage(state) {
            state.accessToken = localStorage.getItem('access_token')
            state.refreshToken = localStorage.getItem('refresh_token')

        },
        refreshStorage(state){
            state.accessToken = localStorage.getItem('access_token')
        },
        refreshTask(state,refreshTask) {
            state.refreshTimer = refreshTask  
        },
        cancelRefreshing(state) {
            clearTimeout(state.refreshTimer)
        },
        unsetUserData: (state) => {
            
            state.isLoggedIn = false
            state.accessToken = null
            state.refreshToken = null
            
        },
        
    },
    actions: {
        userLogin(context, userCredentials) {
            return new Promise((resolve, reject) => {
                console.log("Access", userCredentials.email)
                
                getAPI.post('/api-token/', {
                    email: userCredentials.email,
                    password: userCredentials.password,
                    
                })
                .then(response => {
                    
                    console.log("Access",response.data.access)
                    console.log("Refresh",response.data.refresh)
                    // { access: response.data.access, refresh: response.data.refresh }
                    
                    localStorage.setItem('access_token', response.data.access)
                    localStorage.setItem('refresh_token', response.data.refresh)
                    context.commit('updateStorage')
                    context.dispatch('autoRefresh')
                    context.commit('isLoggedIn', true)
                  
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
            })
        },
        refreshTokens (context) {
            return new Promise((resolve, reject) => {
            // Do whatever you need to do to exchange refresh token for access token
                getAPI.post('/api-token/refresh/', {
                    refresh: localStorage.getItem('refresh_token')
    
                })
                .then(response => {
                    localStorage.setItem('access_token', response.data.access)
                    context.commit('refreshStorage')
                    context.dispatch('autoRefresh')
                    console.log("Token Refreshed")
                    context.commit('isLoggedIn', true)
                    resolve()
                })
                .catch(err => {
                    reject(err)
                })
            // Finally, call autoRefresh to set up the new timeout
            
          })
        },
        autoRefresh (context) {
            
            const { state, commit, dispatch } = context
            
            const accessToken_  = state.accessToken
            console.log("Here",accessToken_)
            const { exp } = jwt_decode(accessToken_)
            
            const now = Date.now() / 1000 // exp is represented in seconds since epoch
            let timeUntilRefresh = exp - now
            timeUntilRefresh -= (2 * 60) // Refresh 2 minutes before it expires
            const refreshTask = setTimeout(() => dispatch('refreshTokens'), timeUntilRefresh * 1000)
            commit('refreshTask', refreshTask) // In case you want to cancel this task on logout
        },
        logoutUser(context) {
            return new Promise((resolve, reject) => {
                try{
                    context.commit('unsetUserData')
                    context.commit('cancelRefreshing')
                    localStorage.removeItem('access_token')
                    localStorage.removeItem('refresh_token')
                    localStorage.clear()
                    resolve()
                }
                catch(err){
                    reject(err)
                }
               

            })
            
            
        },
        DeviceInfo(context, Info){
            this.state.DeviceSelected = Info
            localStorage.setItem('DeviceSelected',Info.name)
        }
    }
})
