import { render, staticRenderFns } from "./Complain.vue?vue&type=template&id=3e49fd1c&scoped=true&"
import script from "./Complain.vue?vue&type=script&lang=js&"
export * from "./Complain.vue?vue&type=script&lang=js&"
import style0 from "./Complain.vue?vue&type=style&index=0&id=3e49fd1c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e49fd1c",
  null
  
)

export default component.exports