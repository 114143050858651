import Vue from 'vue'
import Router from 'vue-router'
// import Home from './components/Home.vue'
// import Login from './components/Login.vue'
import Login_A from './components/Login_Alternate.vue'
import Posts from './Posts.vue'
import logout from './components/Logout.vue'
import DueClearing from './DueClearingPanel.vue'
import Complain from './Complain.vue'
import ComplainStatus from './Complain_Status.vue'
import RefillProduct from './refill_product.vue'
import UserList from './user_list.vue'

import Mismatch from './mismatchh.vue'
import impactData from './impactData.vue'



Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name:'home',
            component: Login_A
        },
        {
            path: '/login',
            name: 'Login',
            component: Login_A
        },
        {
            path: '/devices',
            name: 'posts',
            component: Posts
        },
        {
            path: '/logout',
            component: logout
        },
        {
            path: '/dueclearing',
            name: 'dueClear',
            component: DueClearing
        },
        {
            path: '/Complain',
            name: 'complain',
            component: Complain
        },
        {
            path: '/ComplainStatus',
            name: 'ComplainStatus',
            component: ComplainStatus
        },
        {
            path: '/refillProduct',
            name: 'Requisition',
            component: RefillProduct
        },

        {
            path: '/mismatchh',
            name: 'tryRequisition',
            component: Mismatch
        },
        

        {
            path: '/userList',
            name: 'userList',
            component: UserList
        },
        {
            path: '/impactData',
            name: 'impactData',
            component: impactData
        },

        // {path: '/login_A',component: Login_A}
    ]
})
