import { render, staticRenderFns } from "./Complain_Status.vue?vue&type=template&id=453d75f7&scoped=true&"
import script from "./Complain_Status.vue?vue&type=script&lang=js&"
export * from "./Complain_Status.vue?vue&type=script&lang=js&"
import style0 from "./Complain_Status.vue?vue&type=style&index=0&id=453d75f7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "453d75f7",
  null
  
)

export default component.exports