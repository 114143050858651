<template>
    <div class="container-fluid">
        <Navbar></Navbar>
        <div class="container contact-form">

            <div class="contact-image">
                <img src="https://image.ibb.co/kUagtU/rocket_contact.png" alt="rocket_contact" />
            </div>
            <form @submit.prevent="onClickLog">
                <h3>Log your complain here!</h3>
                <div class="row">
                    <div class="col-md-6">

                        <div class="form-group">
                            <input type="text" name="txtName" v-model="userName" class="form-control"
                                placeholder="Your Name *" value="" />
                        </div>

                        <div class="form-group">
                            <input type="text" name="txtPhone" v-model="userPhone" class="form-control"
                                placeholder="Your Phone Number*" value="" />
                        </div>

                        <div class="form-group">
                            <input type="text" name="txtEmail" v-model="userEmail" class="form-control"
                                placeholder="Your Email" value="" />
                        </div>

                        <div class="form-group">
                            <select class="form-control form-select form-select-sm" v-model="cdeviceSelected"
                                aria-label=".form-select-sm example">
                                <option disabled value="default">Device Name*</option>
                                <option v-for="ruData in APIData" :key="ruData.id" :value="ruData.name">
                                    {{ ruData.name }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <select class="form-control form-select form-select-sm" v-model="complainNature"
                                aria-label=".form-select-sm example">
                                <!-- <option v-for="(item,key) in CN_types" :value="key" :selected="complainNature === Nature" :key="key">{{ item }}</option> -->
                                <option disabled value="default">Nature of Complain*</option>
                                <option value="Inventory">Inventory Mismatch Issue</option>
                                <option value="Inventory">Challan Related Issue</option>
                                <option value="Device">Device Drop Sensor Issue</option>
                                <option value="Device">Device ATS Issue</option>
                                <option value="Device">Device Motor Issue</option>
                                <option value="Device">Product Not Dispensed Issue</option>
                                <option value="Device">RFID Reader Issue</option>
                                <option value="Device">Network Issue</option>
                                <option value="Device">Modem Issue</option>
                                <option value="Device">Device Inactive Issue</option>
                                <option value="Product">Product Issue</option>
                                <option value="Server">User Card Related Issue</option>
                                <option value="Server">User Card Registration Issue</option>
                                <option value="Server">User Limit Issue</option>
                                <option value="Server">Product Missing Issue</option>
                                <option value="HRM">Agent Issue</option>
                                <option value="Payment">Payment Issue</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <select class="form-control form-select form-select-sm" v-model="complainClass"
                                aria-label=".form-select-sm example">
                                <!-- <option v-for="(item,key) in CN_types" :value="key" :selected="complainNature === Nature" :key="key">{{ item }}</option> -->
                                <option disabled value="default">Complain Class*</option>
                                <option value="General">General</option>
                                <option value="Emergency">Emergency</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <input type="submit" name="btnSubmit" class="btnContact" value="Submit" />
                        </div>

                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <textarea name="txtMsg" v-model="complainDescription" class="form-control"
                                placeholder="Your Complain *" style="width: 100%; height: 150px;"></textarea>
                        </div>
                        <div class="form-group">
                            <input type="file" class="form-control" id="file" ref="file" @change="handleFileUpload()" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

import Navbar from './components/Navbar.vue';
import { getAPI } from './axios-api.js'
import { mapState } from 'vuex'

export default {
    name: 'Complain',
    components: {
        Navbar,
    },
    data() {
        return {
            userEmail: '',
            userName: '',
            userPhone: '',
            cdeviceSelected: 'default',
            complainNature: 'default',
            complainClass: 'default',
            complainDescription: '',
            file: '',
            RUData: ''
        }
    },

    computed: mapState(['APIData']),
    created() {
        console.log("here")
        getAPI.get('/requestName/', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`
            }
        })
            .then(response => {
                this.$store.state.APIData = response.data["ruData"]
                console.log(response.data)
                // console.log("Getting Data")
            })
            .catch(err => {
                console.log(err)
            })
    }

    , methods: {
        selectedDevice(selectedDevice) {
            console.log("Device Selected")
            console.log(selectedDevice.name)
            this.$store.dispatch('DeviceInfo', selectedDevice)
            // this.$store.state.selectedDevice = this.DeviceSelected
            this.$router.push({ name: 'Requisition' })
        },

        async onClickLog() {
            var formData = new FormData();
            formData.append('user_Name', this.userName)
            formData.append('user_Email', this.userEmail)
            formData.append('device_Name', this.cdeviceSelected)
            formData.append('Complain_Nature', this.complainNature)
            formData.append('Complain_Class', this.complainClass)
            formData.append('Complain_Summary', this.complainDescription)
            formData.append('user_Phone', this.userPhone)
            console.log("Working!!")
            console.log(this.file)
            formData.append('proof_File', this.file)

            
            for (var pair of formData.entries()) {
                console.log(pair[0] + ', ' + pair[1]);
            }


            try {
                const disdata = await getAPI.post('/complainLog/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`

                    }
                })
                console.log(disdata["data"]["session_payload"])
                this.$router.push('/ComplainStatus');
            } catch (error) {
                console.log("Error")
                console.log(error)
                this.$router.go()
            }
            this.$router.push('/ComplainStatus');
            //window.location.href=ComplainStatus
        },
        handleFileUpload() {
            console.log("GOODY GOOD")
            this.file = this.$refs.file.files[0];
            console.log(this.file)
        }
        // onFileChange(e) {
        //     var files = e.target.files || e.dataTransfer.files;
        //     if (!files.length)
        //         return;
        //     this.createImage(files[0]);
        // },
        // createImage(file) {
        //     var image = new Image();
        //     var reader = new FileReader();
        //     var vm = this;

        //     reader.onload = (e) => {
        //         vm.image = e.target.result;
        //     };
        //     reader.readAsDataURL(file);
        // }


    }
}
</script>

<style scoped>
body {
    background: -webkit-linear-gradient(left, #0072ff, #00c6ff);
}

.contact-form {
    background: #fff;
    margin-top: 10%;
    margin-bottom: 5%;
    width: 70%;
}

.contact-form .form-control {
    border-radius: 1rem;
}


#customFile {
    margin: auto !important;
}

.contact-image {
    text-align: center;
}

.contact-image img {
    border-radius: 6rem;
    width: 11%;
    margin-top: -3%;
    transform: rotate(29deg);
}

.contact-form form {
    padding: 14%;
}

.contact-form form .row {
    margin-bottom: -7%;
}

.contact-form h3 {
    margin-bottom: 8%;
    margin-top: -10%;
    text-align: center;
    color: #0062cc;
}

.contact-form .btnContact {
    width: 50%;
    border: none;
    border-radius: 1rem;
    padding: 1.5%;
    background: #dc3545;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}

.btnContactSubmit {
    width: 50%;
    border-radius: 1rem;
    padding: 1.5%;
    color: #fff;
    background-color: #0062cc;
    border: none;
    cursor: pointer;
}

#fileSelector {
    text-align: right;
}
</style>
