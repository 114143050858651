<template>
    <div class="Final Requisition">
        <Navbar></Navbar>
        <div class="container pt-3 pb-5">
            <div class="table-responsive mt-3" v-if="magazines.length">
                <h2>Device Name: {{DeviceSelected.name}} </h2>
                <br>
                <h3 style="color: red; text-align: center;"><b> ডিভাইসে যে সকল প্রডাক্ট আছে তার একটা লিস্ট নিচে দেওয়া
                        হলো।</b> </h3>
            </div>

            <div class="table-responsive mt-3" v-if="magazines.length">
                <table class="table table-bordered table-hover text-center cart-table" v-if="magazines.length">
                    <thead class="thead-dark">
                        <tr class="table-head">
                            <th class="d-none d-md-table-cell d-lg-table-cell">Magazine Number</th>
                            <th scope="col">Product</th>
                            <th scope="col">Maximum Capacity</th>
                            <th scope="col">Available On Server </th>
                            

                            <!-- <th scope="col">Refill Quantity</th>-->
                            <th scope="col">Available on Device</th>    <!-- form-->
                            <th scope="col">Action</th>
                        </tr>
                    </thead>

                    <tbody v-for="(item1, index) in magazines" :key="index">
                        <tr>
                            <td class="d-none d-md-table-cell d-lg-table-cell">
                                <h6> {{ item1.magazine_number }}</h6>
                            </td>
                            <td>
                                <h6> {{ item1.product_type }}</h6>
                            </td>
                            <td>
                                <h6> {{ item1.max_capacity }}</h6>
                            </td>
                            <td>
                                <h6> {{ item1.count }}</h6>   <!-- Available On Server -->
                            </td>

                            <td>
                                

                                <!-- <input type="text" v-model="selectedRequestProduct" placeholder=""> -->
                                <b-form>
                                    <b-form-group id="text-group" label-for="text-input">
                                        <b-form-input v-model="selectedRequestProduct[index]" type="text"
                                            placeholder="">{{ selectedRequestProduct[index]}}</b-form-input>
                                    </b-form-group>
                                </b-form>
                            </td>

                            <td>
                                <button @click="OnClickRefillProduct(item1, index)"
                                    class="btn btn-danger pull-right btn-sm">Update</button>
                            </td>
                        </tr>
                    </tbody>


                </table>



            </div>


        </div>
    </div>
</template>

<script>

import Navbar from './components/Navbar.vue';
import { getAPI } from './axios-api.js';
import { BFormInput, BFormGroup, BForm } from 'bootstrap-vue'
//import { mapState } from 'vuex'
import moment from 'moment'

export default {
    name: 'Requsition',
    components: {
        Navbar,
        'b-form-input': BFormInput,
        'b-form-group': BFormGroup,
        'b-form': BForm
    },

    created() {
        this.loadData()
        console.log("here")

    },
    data() {
        return {
            DeviceSelected: '',
            DeviceSelectedName: '',
            magazines: '',
            selectedRequestProduct: [],
            agent_products: '',
            tmode: 'False',
            status: '',

        }
    },
    methods: {
        parseDateString(date) {
            return moment(String(date)).format('DD.MM.YYYY')
        },
        async loadData() {
            var disdata = {}

            this.DeviceSelectedName = localStorage.getItem('DeviceSelected')
            console.log(this.DeviceSelectedName)
            const payload = {
                name: this.DeviceSelectedName
            }
            getAPI.post('/singleProduct/', payload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`
                }
            })
                .then(response => {
                    disdata = response['data']['SRU'];
                    console.log(disdata);
                    this.DeviceSelected = disdata;
                })

            getAPI.post('/getMagazines/', payload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`
                }
            })
                .then(response => {
                    disdata = response['data'];
                    console.log(disdata);
                    this.magazines = disdata['magazines']
                })

            getAPI.post('/agentProducts/', payload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`
                }
            })
                .then(response => {
                    disdata = response['data'];
                    console.log(disdata);
                    this.agent_products = disdata['agent_product']
                })

            console.log(this.$store.state.tryselectedDevice)
        },


        async OnClickRefillProduct(item, index) {
            console.log(this.selectedRequestProductindex)

            const payload = {
                magazineInfo: item,  //value
                quantity:  this.selectedRequestProduct[index],
                Device: this.DeviceSelectedName,

            };
            console.log("Recharge payload", payload);
            if (typeof payload.amount !== 'undefined') {
                console.log("Quantity is OK!")
            }
            else if ((parseInt(payload.quantity)  > parseInt(item.max_capacity))) {
                alert("Quantity is greater than max capacity");
            }
            else {

                var disdata = {}

                getAPI.post('/mismatch/', payload, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`
                    }
                })
                    .then(response => {
                        disdata = response['data'];

                        console.log(disdata);
                        this.$router.go()
                        alert("update Complete");
                    })

            }
        },

    }
}
</script>

<style scoped>

</style>
  


