<template>
    
        <div class="duePanel">
            <Navbar></Navbar> 
               
            <div class = "container pt-3 pb-5">
                <!-- <div class="col-sm-6 pull-right"> -->
                <input type="text" v-model="search" class="form-control" placeholder="Search using Employee Code...."  />
                                                
                <div class="table-responsive mt-3" v-if="postpaid_data.length">
                    
                    <table
                    class="table table-bordered table-hover text-center cart-table"
                    v-if="postpaid_data.length"
                    >
                    <thead class="thead-dark">
                        <tr class="table-head">
                         <th scope="col">VIL User ID</th>
                        <th scope="col">Employee Code</th>
                        <th scope="col">Name</th>
                        <th class="d-none d-md-table-cell d-lg-table-cell"    
                            scope="col"
                        >
                            Contact Number
                        </th>
                       <th class="d-none d-md-table-cell d-lg-table-cell"    
                            scope="col">
                            Tag
                        </th>
                        <th
                            
                            scope="col"
                        >
                            Due Amount (tk)
                        </th>
                        </tr>
                    </thead>
                    
                    <tbody  v-for="(item, index) in filteredData" :key="index">
                        <tr>
                            <td >
                                <h5> {{ item.vil_user_id[1]  }}</h5>
                            </td>
                            <td class="d-none d-md-table-cell d-lg-table-cell">
                                <h5>
                                {{ item.vil_user_id[2] }} 
                                </h5>
                            </td>
                            <td >
                                <h5>
                                {{ item.name[0]  }} 
                                </h5>
                            </td>
                            <td >
                                <h5>
                                {{ item.name[1] }} 
                                </h5>
                            </td>
                            <td >
                                <h5>
                                {{ item.vil_user_id[0] }} 
                                </h5>
                            </td>
                            <td >
                                <h5>
                                {{ item.due_amount }} 
                                </h5>
                            </td>


                        </tr>
                    </tbody>
                    
                    </table>
                    
                    
                </div>
            </div>
        </div>
    
</template>

<script>
import Navbar from './components/Navbar.vue';
import { getAPI } from './axios-api.js'
import { mapState } from 'vuex'

export default {
    name: 'DueClearing',
    components: {
      Navbar,
    },
    
    beforemount(){
        
    },
    beforeCreate(){
        
    },
    created() {
        this.loadDueAmount()
        console.log(this.usableData)
    },
    computed: {
        filteredData:function()
        {
            return this.postpaid_data.filter((data) =>
            {
                return data.vil_user_id[2].match(this.search)

            });
        },
        resultQuery(){
        if(this.sepostpaid_dataarchQuery){
            var filtered_res = {}
            console.log(this.searchQuery);
            filtered_res =  this.usableData.filter((item)=>{
                return this.searchQuery.toLowerCase().split(' ').every(
                    v => item.rfidData.tag.toLowerCase().includes(v) || item.rfidData.employee_code.toLowerCase().includes(v)
                )
            })
            console.log(filtered_res);
            return filtered_res;
        }else{
            return this.usableData;
        }
        },
        ...mapState(['usableData'])
        
    },
    data(){
        return {
            searchQuery: '',
            postpaid_data: [],
            rfiddata: [],
            selectedDueAmount: [],
            search: '',
        }
        
    },
    
    methods: {
        // goToDetails(rfIDdat) {
        //     console.log("Click Working")
        //     console.log(rfIDdat.tag)

        // },
        async OnClickDuePayment(item,index) {
            console.log(this.selectedDueAmount[index])
            const payload = {
                amount: '' + this.selectedDueAmount[index],
                rfid_id: item.rfid,
                agent_id: item.agent_name

            };
            
            console.log("Recharge payload", payload);
            if (typeof payload.amount !== 'undefined'){
                console.log("Payment amount OK!")
            }
            else{
                console.log("Not OK!")
            }
            try {
                const disdata = await getAPI.post('/dueClear/', payload, { headers: { 
                    Authorization: `Bearer ${localStorage.getItem('access_token')}` 
            }})
                // this.status_data = disdata["data"]["session_payload"];
                console.log(disdata["data"]["session_payload"])

                //this.$bvModal.show("refund-item-details-modal");
                //const redirect_uri ='/users/dashboard'  + '?refund=' + status['status']
                //console.log(redirect_uri)
                //this.$router.replace('/users/dashboard' + '?refund=' + status['status'])
                this.$router.go()
            } catch (error) {
                
                // this.$notify({
                //     group: 'master',
                //     type: 'error',
                //     title: 'Error!',
                //     text: "Error in Payment"
                // })
                

                console.log(error)
                this.$router.go()
            } finally {

                console.log("Finally not implemented")
                // this.$router.go()
            }
        },
        async loadDueAmount(){
            var disdata = {}
            getAPI.get('/userRFIDInfo/',{ headers: { 
                    Authorization: `Bearer ${localStorage.getItem('access_token')}` 
            } })
            .then(response => {
                // console.log("response?")
                // console.log(response.data)
                disdata = response;
                console.log("disdata");
                console.log(disdata.data);
                this.postpaid_data = disdata["data"]["postpaid_data"];

            })
            .catch(err => {
                console.log(err)
            });
            

        
        }
            
    }
    
}
</script>


<style scoped>
    /* .container {
        padding-top: 90px !important;
    } */
    /* .form-control {
        margin: top 6in;
    }
    .input-sm {
        margin-top:auto;
    } */
    
    /* [class*="col-"] {
    
        float: none;
        margin-right: auto;
        margin-left: 9in;
        
    }
    .form-control {
        display: inline-block;
    }
    .input-sm {
        width: auto;
        
    } */
</style>
