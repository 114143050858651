<template>
    
        <div class="Complain Status">
            <Navbar></Navbar> 


               
            <div class = "container pt-3 pb-5"> 
                <h2><span class="badge badge-dark">Complain Status</span></h2>
                <!-- <div class="col-sm-6 pull-right"> -->
                <input class="form-control input-sm" id="tableSearch" type="text" v-model="searchQuery" placeholder="Search By Problem Name...">
                <!-- </div> -->
                                                
                <div class="table-responsive mt-3" >
                    
                    <table
                    class="table table-bordered table-hover text-center cart-table"
                    >
                    <thead class="thead-light">
                        <tr class="table-head">
                        <th scope="col">Complainant ID</th>
                        <th scope="col">Complainant</th>
                        <th scope="col">Reference Number</th>
                        <th scope="col">Device Name</th>
                        <th scope="col">Complain Nature</th>
                        <th    
                            scope="col"
                        >
                            Problem Name
                        </th>
                        <th 
                            scope="col"
                        >
                            Last Updated
                        </th>
                        <th
                            
                            scope="col"
                        >
                            Status
                        </th>
                        
                       
                        </tr>
                    </thead>
                    
                    <tbody  v-for="(item1, index) in resultQuery" :key="index">
                        <tr>
                        <td >
                            <h5> {{ index+1  }}</h5>
                        </td>

                        <td >
                            <h5> {{ item1.name }}</h5>
                        </td>
                        
                        <td >
                            <h5> {{ item1.contact_number }} </h5>
                        </td>

                        <td >
                            <h5> {{ item1.Device_Name }} </h5>
                        </td>

                        <td >
                            <h5> {{ item1.complain_Nature }} </h5>
                        </td>

                        <td >
                            <h5> {{ item1.complain_log }} </h5>
                        </td>
                        <td >
                            <h5>
                            {{ parseDateString(item1.updated_date) }} 
                            </h5>
                        </td>
                            <td >
                            <h5>
                            {{ item1.status }} 
                            </h5>
                        </td>
                        
                       
                        
                        </tr>
                    </tbody>
                    
                    </table>
                    
                    
                </div>
            </div>
        </div>
    
</template>

<script>
import Navbar from './components/Navbar.vue';
import { getAPI } from './axios-api.js'
import moment from 'moment'
export default {
    name: 'ComplainStatus',
    components: {
      Navbar,
      
    },
    created() {
        this.loadComplainStatus();
    },

    computed: {
        filteredData:function()
        {
            return this.complainData.filter((data) =>
            {
                return data.complainData.complain_Nature.match(this.search)

            });
        },
        resultQuery(){
            if(this.searchQuery){
                var filtered_res = {}
                console.log(this.searchQuery);
                filtered_res =  this.complainData.filter((item)=>{
                    return this.searchQuery.toLowerCase().split(' ').every(
                        v => item.contact_number.toLowerCase().includes(v) || item.name.toLowerCase().includes(v)
                    )
                })
                console.log(filtered_res);
                return filtered_res;
            }else{
                return this.complainData;
            }
        }
    },
    data(){
        return {
            searchQuery : '',
            complainData : [],
            search: ''
            
        }
    },
    methods : {
        
        loadComplainStatus(){
            var recievedData = {}
            getAPI.get('/complainStatus/',{ headers: { 
                    Authorization: `Bearer ${localStorage.getItem('access_token')}` 
            } })
            .then(response => {
                recievedData = response;
                console.log(recievedData.data);
                this.complainData = recievedData["data"]["Complain_Status"];

                
            }
            )
        },
        parseDateString(date) {
            return moment(String(date)).format('DD.MM.YYYY h:mm A')
        },
    }
}
</script>

<style scoped>
    .container {
        padding-top: 90px !important;
    }
    
    span.badge {
        font-size: 22px;
        display:table;
        margin-left: auto;
        margin-right: auto;
        /* display: inline-block;
        align-content: center; */
        /* margin: auto; */
    }
    .label.label-default {
        text-align: center;

    }
</style>
