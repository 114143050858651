<template>
    <div class="container-fluid">
        <Navbar></Navbar>
        <div class="container contact-form">

            <div class="contact-image">
                <img src="https://w7.pngwing.com/pngs/818/133/png-transparent-computer-icons-survey-methodology-laborious-miscellaneous-angle-logo-thumbnail.png" alt="rocket_contact" />
            </div>
            <form @submit.prevent="onClickLog">
                <h3>এখানে আপনার ডেটা লগ করুন!!</h3>
                <div class="row">
                    <div class="col-md-8">
                      <label> প্রতিষ্ঠানের নামঃ </label>
                      <br>
                      <div class="form-group">
                            <select class="form-control form-select form-select-sm" v-model="cdeviceSelected"
                                aria-label=".form-select-sm example">
                                <option disabled value="default">Device Name*</option>
                                <option v-for="ruData in APIData" :key="ruData.id" :value="ruData.name">
                                    {{ ruData.name }}
                                </option>
                            </select>
                        </div>

                      
                        <div class="form-group">
                          <label> জ্যোতি/ আরএফআইডি/ এম্প্লয়ী নাম্বারঃ * </label>
                            <input type="text" name="txtName" v-model="userjyotiId" class="form-control"
                                placeholder="" value="" />
                        </div>
                        <div class="form-group">
                            <input type="text" name="txtName" v-model="username" class="form-control"
                                placeholder="ব্যবহারকারীর নাম " value="" />
                        </div>

                        <div class="form-group">
                            <input type="text" name="txtName" v-model="userageId" class="form-control"
                                placeholder="বয়সঃ *" value="" />
                        </div>
                        <div class="form-group">
                            <input type="text" name="txtName" v-model="usermobile" class="form-control"
                                placeholder="মোবাইল নাম্বারঃ " value="" />
                        </div>


                        <br>

                        <div class="form-group">
                          <label> Affordability: </label>
                          <br>
                            <label for="productDescription">১। আপনার পরিবারের উপার্জনক্ষম ব্যক্তি কে কে?</label>
                            <div class="checkbox-group">
                                <label v-for="(option, index) in productOptions" :key="index">
                                <input
                                    type="checkbox"
                                    v-model="selectedProducts"
                                    :value="option"
                                    :id="'product-' + index"
                                    name="productDescription"
                                />
                                {{ option }}
                                </label>
                            </div>
                        </div>

           

                        <div class="form-group">
                          
                        <label for="recommendation1">২। পরিবারের মাসিক আয় আনুমানিক কত টাকা? </label>

                            <select class="form-control form-select form-select-sm" v-model="incomeClass"
                                
                                aria-label=".form-select-sm example">
                                <!-- <option v-for="(item,key) in CN_types" :value="key" :selected="complainNature === Nature" :key="key">{{ item }}</option> -->
                                <option disabled value="default">ব্যবহারকারীর মতামত*</option>
                                <option value="Below 10000"> ১০০০০ এর কম</option>
                                <option value="10-20000">১০-২০০০০</option>
                                <option value="21-30000">২১-৩০০০০</option>
                                <option value="31-40000">৩১-৪০০০০</option>
                                <option value="40-50000">৪০-৫০০০০</option>
                                <option value="More than 50000">৫০০০০ এর বেশি</option>
                                
                            </select>
                        </div>
                        <br>
                        <div class="form-group">
                            <label> ৩। আপনার আনুমানিক মাসিক আয় কত? </label>
                            <input type="text" name="txtName" v-model="userincome" class="form-control"
                                placeholder="___টাকা" value="" />
                        </div>
                        <div class="form-group">
                            <label> ৪। আপনি আপনার জন্য প্রতি মাসে কত টাকা খরচ করেন? </label>
                            <input type="text" name="txtName" v-model="spendmoney" class="form-control"
                                placeholder="___টাকা" value="" />
                        </div>
                        
                        <div class="form-group">
                        <label for="recommendation2">৫। আপনার খরচের টাকা থেকে কি স্যানিটারি ন্যাপকিন কেনা হয়? </label>

                            <select class="form-control form-select form-select-sm" v-model="buypad"
                                
                                aria-label=".form-select-sm example">
                                <!-- <option v-for="(item,key) in CN_types" :value="key" :selected="complainNature === Nature" :key="key">{{ item }}</option> -->
                                <option disabled value="default">ব্যবহারকারীর মতামত*</option>
                                <option value="Yes">হ্যাঁ</option>
                                <option value="No">না</option>                                                    
                            </select>
                        </div>
                        <div class="form-group">
                            <label> ৬। আপনি যদি স্যানিটারি ন্যাপকিন কিনতে টাকা খরচ করেন তাহলে প্রতি মাসে কত খরচ হয়?</label>
                            <input type="text" name="txtName" v-model="userbuyingpadcost" class="form-control"
                                placeholder="___টাকা" value="" />
                        </div>
                        <div class="form-group">
                            <label> ৭। যদি স্যানিটারি ন্যাপকিন না কেনা হয়, তাহলে এর পিছনে কারণ কী?</label>
                            <input type="text" name="txtName" v-model="userreason" class="form-control"
                                placeholder="স্যানিটারি ন্যাপকিন না কেনার কারণ* " value="" />
                        </div>
                        
                        <div class="form-group">
                            <label> ৮। আপনি কি স্যানিটারি ন্যাপকিন কেনার জন্য পরিবারের সাহায্য পান?</label>
                            <input type="text" name="txtName" v-model="familysupport" class="form-control"
                                placeholder="" value="" />
                        </div>

                        
                        <br>

                        <label> Awareness: </label>
                        <br>

                        <div class="form-group">
                          <label for="recommendation2">১। আপনি মাসিকের সময় কোন ধরনের শোষনকারী পন্য ব্যবহার করেন?</label>
                          <select class="form-control form-select form-select-sm" v-model="suctioningProducts" aria-label=".form-select-sm example">
                              <option disabled value="default">ব্যবহারকারীর মতামত*</option>
                              <option value="Pad">প্যাড/ন্যাপকিন</option>
                              <option value="Cotton">তুলা</option>
                              <option value="Wash clothes/rags repeatedly">বার বার কাপড়/ন্যাকড়া ধুয়ে ব্যবহার</option>
                              <option value="other">অন্যান্য</option>
                          </select>

                          <!-- Conditionally render the text input based on the selected value -->
                          <input v-if="suctioningProducts === 'other'" type="text" name="txtName" v-model="otherSuctioningProduct" class="form-control" placeholder="" value="" />
                      </div>

                      <div class="form-group">
                        <label for="recommendation2">২। আপনি কি স্যানিটারি ন্যাপকিন সম্পর্কে কিছু জানেন?</label>
                            <div class="checkbox-group">
                                <label v-for="(option, index) in productOptionspad" :key="index">
                                <input
                                    type="checkbox"
                                    v-model="selectedProductspad"
                                    :value="option"
                                    :id="'product-' + index"
                                    name="productDescription"
                                />
                                {{ option }}
                                </label>
                            </div>
                        </div>

                        <div class="form-group">
                          <label for="recommendation2" style="color: red;">৩। কোন ধরনের শোষনকারী পন্য ব্যবহার করা সর্বাধিক নিরাপদ বলে মনে করেন?</label>

                            <select class="form-control form-select form-select-sm" v-model="suctioningProductsSafe"
                                
                                aria-label=".form-select-sm example">
                                <!-- <option v-for="(item,key) in CN_types" :value="key" :selected="complainNature === Nature" :key="key">{{ item }}</option> -->
                                <option disabled value="default">ব্যবহারকারীর মতামত*</option>
                                <option value="Pad">প্যাড/ন্যাপকিন</option>
                                <option value="Cotton">তুলা</option>
                                <option value="Wash clothes/rags repeatedly">বার বার কাপড়/ন্যাকড়া ধুয়ে ব্যবহার</option>
                                <option value="other">অন্যান্য</option>
                            </select>
                            <input v-if="suctioningProductsSafe === 'other'" type="text" name="txtName" v-model="otherSuctioningProductSafe" class="form-control" placeholder="" value="" />

                        </div>
                        <div class="form-group">
                        <label for="recommendation2">৩। আপনি কত সময় পর পর শোষনকারী পন্য পরিবর্তন করেন?</label>

                            <select class="form-control form-select form-select-sm" v-model="changeFrequency"
                                
                                aria-label=".form-select-sm example">
                                <!-- <option v-for="(item,key) in CN_types" :value="key" :selected="complainNature === Nature" :key="key">{{ item }}</option> -->
                                <option disabled value="default">ব্যবহারকারীর মতামত*</option>
                                <option value="less than 4 hour"> ৪ ঘন্টার কম</option>
                                <option value="4-5 hours">৪-৫ ঘন্টা</option>
                                <option value="more than 5 hour">৫ ঘন্টার বেশি</option>
                                
                            </select>
                        </div>
                        <div class="form-group">
                        <label for="recommendation2" style="color: red;">৪। আপনি ব্যবহারের পর শোষনকারী পন্য কোথায় বা কিভাবে ফেলে দেন?</label>

                            <select class="form-control form-select form-select-sm" v-model="disposalMethod"
                                
                                aria-label=".form-select-sm example">
                                <!-- <option v-for="(item,key) in CN_types" :value="key" :selected="complainNature === Nature" :key="key">{{ item }}</option> -->
                                <option disabled value="default">ব্যবহারকারীর মতামত*</option>
                                <option value="Straight to the trash">সরাসরি ময়লার ঝুড়িতে</option>
                                <option value="Wrapped in paper/packet in waste basketl">কাগজ/প্যাকেটে মুড়ে ময়লার ঝুড়িতে</option>
                                <option value="outside in the open">ময়লার সাথে রাখি না, সাথে সাথেই বাইরে খোলা জায়গায় ফেলি</option>
                                <option value="other">অন্যান্য</option>
                            </select>
                            <input v-if="disposalMethod === 'other'" type="text" name="txtName" v-model="otherdisposalMethod" class="form-control" placeholder="" value="" />

                        </div>
                        <br>
                        
                        
                        <label> MHM Status: </label>
                        <br>
                        <div class="form-group">
                          <label style="color: red;"> ১। কত বছর বয়সে আপনার মাসিক শুরু হয়? </label>
                            <input type="text" name="txtName" v-model="userperiodageId" class="form-control"
                                placeholder="___বছর" value="" />
                        </div>
                        <br>
                        <div class="form-group">
                            <label for="productDescription1">২। আপনি কখনো মাসিক সংক্রান্ত কোন স্বাস্থ্য সমস্যায় ভুগেছেন বা ভুগছেন কিনা.</label>
                            <div class="checkbox-group">
                                <label v-for="(option, index) in productOptions1" :key="index">
                                <input
                                    type="checkbox"
                                    v-model="selectedProducts1"
                                    :value="option"
                                    :id="'product-' + index"
                                    name="productDescription1"
                                />
                                {{ option }}
                                </label>
                            </div>
                        </div>
                        <div class="form-group">
                        <label for="recommendation2" style="color: red;">৩। মাসিক সংক্রান্ত কোন স্বাস্থ্য সমস্যা হলে সাধারনত কি করেন?</label>

                            <select class="form-control form-select form-select-sm" v-model="actionsforMenstrual"
                                
                                aria-label=".form-select-sm example">
                                <!-- <option v-for="(item,key) in CN_types" :value="key" :selected="complainNature === Nature" :key="key">{{ item }}</option> -->
                                <option disabled value="default">ব্যবহারকারীর মতামত*</option>
                                <option value="Concern with doctor">সমস্যা হলেই ডাক্তারের পরামর্শ নেই</option>
                                <option value="ask at the drug store and buy medicine">ওষুধের দোকানে জিজ্ঞেস করে ওষুধ কিনে খাই</option>
                                <option value="Sometimes, I visit the doctor or the pharmacy.">কখনো ডাক্তার দেখাই, কখনো ওষুধের দোকানে জিজ্ঞেস করে ওষুধ কিনে খাই</option>
                                <option value="Don't do anything, just wait to recover">কিছুই না করে অপেক্ষা করি, দেখি যে নিজে নিজে ঠিক হয় কিনা</option>
                                <option value="other">অন্যান্য</option>
                            </select>
                            <input v-if="actionsforMenstrual === 'other'" type="text" name="txtName" v-model="otheractionsforMenstrual" class="form-control" placeholder="" value="" />

                        </div>
                        <div class="form-group">
                        <label for="recommendation2" style="color: red;">৪। মাসিকের সময় গোসল করেন কিনা?</label>

                            <select class="form-control form-select form-select-sm" v-model="bathingMenstruation"
                                
                                aria-label=".form-select-sm example">
                                <!-- <option v-for="(item,key) in CN_types" :value="key" :selected="complainNature === Nature" :key="key">{{ item }}</option> -->
                                <option disabled value="default">ব্যবহারকারীর মতামত*</option>
                                <option value="Yes">হ্যাঁ</option>
                                <option value="No">না</option>                     
                                <option value="other">অন্যান্য</option>
                            </select>
                            <input v-if="bathingMenstruation === 'other'" type="text" name="txtName" v-model="otherbathingMenstruation" class="form-control" placeholder="" value="" />

                        </div>
                        <div class="form-group">
                        <label for="recommendation2" style="color: red;">৫। মাসিকের সময় সাবান/শ্যাম্পু বা অন্য কিছু দিয়ে মাথা পরিষ্কার করেন কিনা?</label>

                            <select class="form-control form-select form-select-sm" v-model="cleaningHead"
                                
                                aria-label=".form-select-sm example">
                                <!-- <option v-for="(item,key) in CN_types" :value="key" :selected="complainNature === Nature" :key="key">{{ item }}</option> -->
                                <option disabled value="default">ব্যবহারকারীর মতামত*</option>
                                <option value="Yes">হ্যাঁ</option>
                                <option value="No">না</option>                     
                                <option value="other">অন্যান্য</option>
                            </select>
                            <input v-if="cleaningHead === 'other'" type="text" name="txtName" v-model="othercleaningHead" class="form-control" placeholder="" value="" />

                        </div>
                        <div class="form-group">
                        <label for="recommendation2">৬। মাসিকের সময় প্রতিদিন আনুমানিক কতটুকু পানি পান করেন?</label>

                            <select class="form-control form-select form-select-sm" v-model="waterConsumption"
                                
                                aria-label=".form-select-sm example">
                                <!-- <option v-for="(item,key) in CN_types" :value="key" :selected="complainNature === Nature" :key="key">{{ item }}</option> -->
                                <option disabled value="default">ব্যবহারকারীর মতামত*</option>
                                <option value="Less than 2 liters">২ লিটার এর কম</option>
                                <option value="2-3 liters">২-৩ লিটার</option>                     
                                <option value="More than 3 liters">৩ লিটার এর বেশি</option>
                            </select>
                        </div>
                        
                        <br>
                        <div class="form-group">
                        <label for="textinfo">৭. আপনার কি অন্য কোন মন্তব্য, প্রশ্ন বা উদ্বেগ আছে?</label>

                            <textarea name="txtMsg" v-model="complainDescription" class="form-control"
                                placeholder="ব্যবহারকারীর মতামত *" style="width: 100%; height: 150px;"></textarea>
                        </div>
                    

                        <div class="form-group">
                            <input type="submit" name="btnSubmit" class="btnContact" value="Submit" />
                        </div>

                    </div>
                </div>
            </form>
        </div>
    </div>
</template>



<script>
import Navbar from './components/Navbar.vue';
import { getAPI } from './axios-api.js';
import { mapState } from 'vuex';

export default {
  name: 'impactData',
  components: {
    Navbar,
  },
  data() {
    return {
      userjyotiId: '',
      userageId: '',
      userperiodageId: '',
      file: null, // Added file property
      userincome:'',
      spendmoney: '',
      userbuyingpadcost: '',
      userreason: '',
      familysupport: '',
      suctioningProducts: '',
      otherSuctioningProduct: '',
      suctioningProductsSafe: '',
      otherSuctioningProductSafe: '',
      disposalMethod: '',
      otherdisposalMethod: '',
      actionsforMenstrual: '',
      otheractionsforMenstrual: '',
      bathingMenstruation: '',
      otherbathingMenstruation: '',
      cleaningHead: '',
      othercleaningHead: '',
      username: '',
      usermobile: '',

      selectedProducts: [],
      //surveySubmitted: false, //SSSSSSSSSSSSSSS
      productOptions: [
        'নিজে',
        'বাবা',
        'মা',
        'স্বামী',
        'স্ত্রী',
        'সন্তান ১',
        'সন্তান ২',
        'পুত্রবধূ ১',
        'পুত্রবধূ ২',
        'ভাই ১',
        'ভাই ২',
        'বোন ১',
        'বোন ২',
        'অন্যান্য',
      ],
      selectedProducts1: [],
      productOptions1: [
        'অনিয়মিত মাসিক',
        'প্রলম্বিত মাসিক (৭-৮ দিনের বেশি)',
        'অসহনীয় পেটে ব্যথা (যার কারনে দৈনন্দিন কার্যক্রম ব্যহত হয়, ছুটি নিতে হয় এবং ঔষধ ছাড়া যে ব্যথা কমতেই চায় না)',
        'অতিরিক্ত এবং অস্বাভাবিক রক্তক্ষরন (যার কারনে দৈনন্দিন কার্যক্রম ব্যহত হয়, ছুটি নিতে হয় এবং ঘন ঘন শোষনকারী পন্য বদলাতে হয়)',
        'প্রস্রাবের রাস্তায় জ্বালাপোড়া',
        'প্রস্রাবের রাস্তা, যৌনাংগ ও এর আশেপাশে দানা, ফোঁড়া, সাদা সাদা ছোপ, গোল গোল চাকার মত দাগ',
        'প্রস্রাবের রাস্তা, যৌনাংগ ও এর আশেপাশে চুলকানি',
        'অন্যান্য',
      ],
      selectedProductspad: [],
      productOptionspad: [
        '০',
        '১',
        '২',
        '৩',
        '৪',
        '৫',
      ],
    };
  },

  computed: mapState(['APIData']),

  created() {
    // ... (your existing created hook code) ...
  },

  methods: {
    async onClickLog() {
      try {
        const formData = new FormData();
        formData.append('userjyotiId', this.userjyotiId);
        formData.append('userageId', this.userageId);
        // Append other form data as needed
        formData.append('device_Name', this.cdeviceSelected)
        formData.append('incomeClass', this.incomeClass);
        //formData.append('suctioningProducts', this.suctioningProducts);
        //formData.append('suctioningProductsSafe', this.suctioningProductsSafe);
        formData.append('changeFrequency', this.changeFrequency);
        //formData.append('disposalMethod', this.disposalMethod);
        //formData.append('actionsforMenstrual', this.actionsforMenstrual);
        //formData.append('bathingMenstruation', this.bathingMenstruation);
        formData.append('userperiodageId', this.userperiodageId);
        //formData.append('cleaningHead', this.cleaningHead);
        formData.append('waterConsumption', this.waterConsumption);
        formData.append('selectedProducts', this.selectedProducts.join(','));
        formData.append('selectedProducts1', this.selectedProducts1.join(','));
        formData.append('selectedProductspad', this.selectedProductspad.join(','));
        formData.append('complainDescription', this.complainDescription);
        formData.append('userincome', this.userincome);
        formData.append('spendmoney', this.spendmoney);
        formData.append('buypad', this.buypad);
        formData.append('userbuyingpadcost', this.userbuyingpadcost);
        formData.append('userreason', this.userreason);
        formData.append('familysupport', this.familysupport);
        formData.append('username', this.username);
        formData.append('usermobile', this.usermobile);

        if (this.suctioningProducts === 'other') {
          formData.append('suctioningProducts', this.otherSuctioningProduct);
        } else {
          formData.append('suctioningProducts', this.suctioningProducts);
        }
        if (this.suctioningProductsSafe === 'other') {
          formData.append('suctioningProductsSafe', this.otherSuctioningProductSafe);
        } else {
          formData.append('suctioningProductsSafe', this.suctioningProductsSafe);
        }
        if (this.disposalMethod === 'other') {
          formData.append('disposalMethod', this.otherdisposalMethod);
        } else {
          formData.append('disposalMethod', this.disposalMethod);
        }
        if (this.actionsforMenstrual === 'other') {
          formData.append('actionsforMenstrual', this.otheractionsforMenstrual);
        } else {
          formData.append('actionsforMenstrual', this.actionsforMenstrual);
        }
        if (this.bathingMenstruation === 'other') {
          formData.append('bathingMenstruation', this.otherbathingMenstruation);
        } else {
          formData.append('bathingMenstruation', this.bathingMenstruation);
        }
        if (this.cleaningHead === 'other') {
          formData.append('cleaningHead', this.othercleaningHead);
        } else {
          formData.append('cleaningHead', this.cleaningHead);
        }
        
        
        
        // Append the file if it exists
        if (this.file) {
          formData.append('proof_File', this.file);
        }

        const response = await getAPI.post('/impactdata/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });

        console.log(response.data['session_payload']);
        this.$router.push('/impactdata');
        //SSSSSSSSSSSSSSSS
        this.surveySubmitted = true;

        // Show a confirmation popup
        const confirmMessage = 'Thank you for submitting the survey! Click OK to reload the page.';
        if (window.confirm(confirmMessage)) {
          // Reload the page
          window.location.reload();
        }
        //SSSSSSSSSSSSSSSS
      } catch (error) {
        console.log('Error', error);
        this.$router.go();
      }
      
      
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    setRecommendation(value) {
      this.recommendation = value;
    },
  },
};
</script>





<style scoped>
body {
    background: -webkit-linear-gradient(left, #0072ff, #00c6ff);
}

.contact-form {
    background: #fff;
    margin-top: 10%;
    margin-bottom: 5%;
    width: 70%;
}

.contact-form .form-control {
    border-radius: 1rem;
}


#customFile {
    margin: auto !important;
}

.contact-image {
    text-align: center;
}

.contact-image img {
    border-radius: 6rem;
    width: 15%;
    margin-top: -15%;
    transform: rotate(29deg);
}

.contact-form form {
    padding: 14%;
}

.contact-form form .row {
    margin-bottom: -14%;
}

.contact-form h3 {
    margin-bottom: 8%;
    margin-top: -15%;
    text-align: center;
    color: #0062cc;
}

.contact-form .btnContact {
    width: 50%;
    border: none;
    border-radius: 1rem;
    padding: 1.5%;
    background: #dc3545;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}

.btnContactSubmit {
    width: 50%;
    border-radius: 1rem;
    padding: 1.5%;
    color: #fff;
    background-color: #0062cc;
    border: none;
    cursor: pointer;
}

#fileSelector {
    text-align: right;
}
</style>


<style scoped>
.slider-container {
  display: flex;
  flex-direction: column;
}

.slider {
  width: 100%;
  margin: 10px 0;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
}

.slider-values {
  display: flex;
  justify-content: space-between;
}
</style>


<style scoped>
.button-group {
  display: flex;
}

button {
  cursor: pointer;
  padding: 8px 12px;
  margin-right: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  outline: none;
}

button.selected {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}
</style>



<style scoped>
.checkbox-group {
  display: flex;
  flex-wrap: wrap;
}

label {
  margin-right: 20px;
}

input {
  margin-right: 5px;
}
</style>
